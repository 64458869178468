import React, { FC } from 'react';
import styled from 'styled-components';
import HintIconSVG from 'assets/icons/hint-icon.svg';
import Text from './Text';
import { mobile } from 'styles/breakpoints';

interface ErrorMessageProps {
  errorText?: string;
}

const Container = styled.div`
  background-color: orange;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 14px;
  background-color: #ea7a74;
  @media ${mobile} {
    padding: 10px 8px;
  }
`;

const ErrorText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.293px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  margin-left: 6px;
  @media ${mobile} {
    font-size: 12px;
  }
`;

const ErrorMessage: FC<ErrorMessageProps> = ({ errorText, ...props }) => {
  if (!errorText) return null;
  return (
    <Container {...props}>
      <HintIconSVG fill="#FFFFFF" />
      <ErrorText>{errorText}</ErrorText>
    </Container>
  );
};

export default ErrorMessage;
